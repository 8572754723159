import "./matching.scss";

import { Alert, Button } from "react-bootstrap";
import React, { Profiler, useEffect, useState } from "react";

import BodyBox from "../../layouts/bodyBox";
import LoadingOverlay from "../../components/LoadingOverlay";
import SEO from "../../components/Seo";
import advancedImg from "../../../data/images/advancedPlan.jpg";
import axios from "axios";
import basicImg from "../../../data/images/basicPlan.jpg";
import checkImg from "../../../data/images/check.jpg";
import expertImg from "../../../data/images/expertPlan.jpg";

const Results = ({ location }) => {
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState([]);
  const [booked, setBooked] = useState(false);
  const [err, setErr] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const params = new URLSearchParams(location.search);
  const userId = params.get("user");
  const attempt = params.get("attempt");
  let countCoachMatch = 0;
  const bookAppointment = () => {
    if (!results.email) {
      setErr(true);
      return;
    }
    // axios({
    //   method: 'post',
    //   data: {
    //     user_id: userId,
    //     coachEmail: 'info@walletgyde.com', // temp
    //     coachName: `${results.first_name} ${results.last_name}`,
    //     // coachEmail: results.coachEmail,
    //   },
    //   url: `${process.env.API_ENDPOINT}/matching/contactCoach`,
    //   withCredentials: true,
    // }).then((res) => {
    //   setErr(false);
    //   setBooked(true);
    // }).catch((err) => {
    //   setErrMsg('Error booking appointment.');
    //   setErr(true);
    // });
    setBooked(true);
  };

  useEffect(() => {
    axios({
      method: "get",

      url: `${process.env.API_ENDPOINT}/matching/getMatchResults/${userId}/${attempt}`,
    })
      .then((response) => {
        setResults(response.data);
      })
      .catch((error) => {
        setErr(true);
        setErrMsg("Error getting match results.");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <BodyBox>
      <SEO title="Match Results" />
      {err ? <Alert variant="danger">{errMsg}</Alert> : null}
      {results.map((result) => {
        countCoachMatch += 1;
        return loading ? (
          <LoadingOverlay message="Loading Results" key={result.coach_id} />
        ) : (
          <div key={result.coach_id}>
            <h1>
              Your Match Results:{" "}
              <span className="text-success">{countCoachMatch}</span>
            </h1>
            <div className="results-container">
              <div className="main-info ">
                {result.avatar_url ? (
                  <div className="containerImg">
                    <img
                      id="coach-avatar"
                      src={result.avatar_url}
                      alt="avatar"
                    />
                  </div>
                ) : null}
                <h2>{`${result.first_name} ${result.last_name}`}</h2>
                <a href={result.email}>{result.email}</a>
              </div>

              <div className="extended-info">
                <div className="booking">
                  <h3>Book an appointment instantly:</h3>
                  <a
                    href="https://calendly.com/desz-walletgyde/30min"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button variant="primary" disabled={booked}>
                      Book Appointment
                      {/* {booked ? "Apointment booked" : "1-click appointment"} */}
                    </Button>
                  </a>
                </div>
                <div className="account">
                  <p>Add match results to Walletgyde account:</p>
                  <Button variant="primary">Sign In</Button>
                  <span className="inline-text">or</span>
                  <Button variant="secondary">Sign Up</Button>
                </div>
                <div className="info-grid">
                  <h3>Website:</h3>
                  {result.website_url ? (
                    <a
                      href={result.website_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {result.website_url}
                    </a>
                  ) : (
                    <p>Not available</p>
                  )}
                  <h3>Phone Number:</h3>
                  {result.phone_number ? (
                    <p>{result.phone_number}</p>
                  ) : (
                    <p>Not available</p>
                  )}
                  <h3>Certfications:</h3>
                  {result.certifications ? (
                    <p>{result.certifications}</p>
                  ) : (
                    <p>Not available</p>
                  )}
                  <h3>Experience:</h3>
                  {result.experience ? (
                    <p>{result.experience}</p>
                  ) : (
                    <p>Not available</p>
                  )}
                  <h3>Expertise: </h3>
                  {result.expertise ? (
                    <p>{result.expertise}</p>
                  ) : (
                    <p>Not available</p>
                  )}
                </div>
              </div>
            </div>
            <hr />
          </div>
        );
      })}
      <div>
        <h5>Next Steps</h5>
        <p>1. Free 30 minute consultation from coach</p>
        <p>2. Review Financial Needs</p>
        <p>3. Get Startd With A Plan That Works For You</p>
        <br />
        <br />
      </div>
      <div className="right-section-container mt-5">
        <div className="pricingPackage-container">
          <div className="priceItem shadow  p-3 mb-5 bg-white" id="priceItem1">
            <h6>BASIC</h6>
            <h1>FREE</h1>
            <h6>SELF-PACED</h6>
            <hr />
            <p>
              <img
                id="check"
                src={checkImg}
                height="18"
                width="20"
                alt="check"
              />
              Identify money problem
            </p>
            <p>
              <img
                id="check"
                src={checkImg}
                height="18"
                width="20"
                alt="check"
              />
              Recommend money apps to use to gather finanical data
            </p>
            <p>
              <img
                id="check"
                src={checkImg}
                height="18"
                width="20"
                alt="check"
              />
              Setup a customizable budget plan
            </p>
            <p>
              <img
                id="check"
                src={checkImg}
                height="18"
                width="20"
                alt="check"
              />
              Streamline all accounts
            </p>
          </div>

          <div className="priceItem shadow  p-3 mb-5 bg-white" id="priceItem2">
            <h6>Advanced</h6>
            <h1>$500</h1>
            <h6>SELF-PACED</h6>
            <hr />
            <p>
              <img
                id="check"
                src={checkImg}
                height="18"
                width="20"
                alt="check"
              />
              Student loan management
            </p>
            <p>
              <img
                id="check"
                src={checkImg}
                height="18"
                width="20"
                alt="check"
              />
              Home buying counseling
            </p>
            <p>
              <img
                id="check"
                src={checkImg}
                height="18"
                width="20"
                alt="check"
              />
              Maximize retirement accounts
            </p>
            <p>
              <img
                id="check"
                src={checkImg}
                height="18"
                width="20"
                alt="check"
              />
              Maximizing insurance benefits
            </p>
            <p>
              <img
                id="check"
                src={checkImg}
                height="18"
                width="20"
                alt="check"
              />
              Tax filling assistance
            </p>
            <p>
              <img
                id="check"
                src={checkImg}
                height="18"
                width="20"
                alt="check"
              />
              Additional video streaming resources
            </p>
          </div>

          <div className="priceItem shadow  p-3 mb-5 bg-white" id="priceItem3">
            <h6>Expert</h6>
            <h1>$1000+</h1>
            <h6>SELF-PACED</h6>
            <hr />
            <p>
              <img
                id="check"
                src={checkImg}
                height="18"
                width="20"
                alt="check"
              />
              3-6 months in depth gudided coaching
            </p>
            <p>
              <img
                id="check"
                src={checkImg}
                height="18"
                width="20"
                alt="check"
              />
              including vidoe resources
            </p>
            <p>
              <img
                id="check"
                src={checkImg}
                height="18"
                width="20"
                alt="check"
              />
              E-books
            </p>
          </div>
        </div>
      </div>
    </BodyBox>
  );
};

export default Results;
